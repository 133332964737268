import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ApplicationForUser } from '../../../models/borrower-portal/application-for-user-model';
import { ApplicationTaskModel, BorrowerTaskStatus, BorrowerTaskType } from '../../../models/borrower-portal/application-task-model';
import * as FileSaver from "file-saver";
import { LinkedDocument } from '../view-document/view-document.component';
import { TasksUtils } from 'projects/shared/tasks.utils';
import { TaskNotApplicableDialogComponent } from '../task-not-applicable-dialog/task-not-applicable-dialog.component';
import { Constants } from '../../../services/constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ApplicationContextService } from '../../../services/application-context.service';

@Component({
  selector: 'tasks-normal',
  templateUrl: './tasks-normal.component.html',
  styleUrls: ['./tasks-normal.component.scss']
})
export class TasksNormalComponent implements OnInit, OnDestroy {

  @Input() tasks: ApplicationTaskModel[] = [];
  @Input() application: ApplicationForUser;
  @Input() isBorrowerPortal: boolean = true;

  @Input() enabledTaskIdForESign: number;

  @Output() openPaymentInfoClicked = new EventEmitter<ApplicationTaskModel>();
  @Output() openEsignDocumentClicked = new EventEmitter<ApplicationTaskModel>();
  @Output() openAttachedDocumentsClicked = new EventEmitter<ApplicationTaskModel>();
  @Output() openAddFilesClicked = new EventEmitter<ApplicationTaskModel>();
  @Output() taskMarkedAsNotApplicable = new EventEmitter<ApplicationTaskModel>();

  protected taskNotApplicable: boolean = false;

  protected isCompanyPhm: boolean = false;

  private _contextSubscription: Subscription;

  constructor(private readonly _modalService: NgbModal,
    private readonly _applicationContextService: ApplicationContextService) { }

  ngOnInit(): void {
    this._contextSubscription = this._applicationContextService.context.subscribe(context => {
      this.isCompanyPhm = context.isCompanyPHM;
      this.tasks.forEach(t => {
        t['attachedDocuments'] = [];
        t.linkedDocuments.forEach(d => {
          const linkedDocument: LinkedDocument = {
            fileGuid: d.documentGuid.toString(),
            fileName: d.fileName,
            note: d.borrowerNote,
            dateInserted: d.dateInserted ? new Date(d.dateInserted) : null,
            mimeType: ''
          }
          t['attachedDocuments'].push(linkedDocument);
        })
      })
    });
  }

  ngOnDestroy(): void {
    this._contextSubscription?.unsubscribe();
  }

  onTaskNotApplicableChanged = (task: ApplicationTaskModel) => {
    if (this.taskNotApplicable) {
      const modalRef = this._modalService.open(TaskNotApplicableDialogComponent, Constants.modalOptions.medium);
      modalRef.componentInstance.task = task;
      modalRef.result.then(result => {
        this.taskMarkedAsNotApplicable.emit(task);
        this.taskNotApplicable = false;
      }, error => {
        this.taskNotApplicable = false;
      });
    }
  }

  openAttachedDocuments = (task: ApplicationTaskModel) => {
    this.openAttachedDocumentsClicked.emit(task);
  }

  openAddFiles(task: ApplicationTaskModel) {
    this.openAddFilesClicked.emit(task);
  }

  goToLink(url: string) {
    window.open(url, "_blank");
  }

  goToFileLink(url: string) {
    window.open(url.replace("httsp://", "https://"), "_blank");
  }

  downloadLink(url: string, fileName: string) {
    FileSaver.saveAs(url.replace("httsp://", "https://"), fileName);
  }

  shouldShowEsignDocumentEmailTextMessage(task: ApplicationTaskModel): boolean {
    return (task.borrowerTaskType === BorrowerTaskType.LosEsign
      || task.borrowerTaskType === BorrowerTaskType.EsignDocument)
      && task.borrowerTaskStatus !== BorrowerTaskStatus.Completed
      && task.signingLinkSentInEmail
  }

  shouldShowEsignDocumentBtn(task: ApplicationTaskModel): boolean {
    return TasksUtils.shouldShowEsignDocument(task, this.isBorrowerPortal);
  }

  openPaymentInfo = (task: ApplicationTaskModel) => {
    this.openPaymentInfoClicked.emit(task);
  }

  openEsignDocument = (task: ApplicationTaskModel) => {
    this.openEsignDocumentClicked.emit(task);
  }

}
