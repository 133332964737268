<div
  class="d-flex align-items-stretch"
  *ngFor="
    let task of tasks | filter : 'taskId' | sort : 'taskId';
    let i = index
  "
>
  <div class="card w-100 mb-4 border border-2">
    <div
      class="card-header px-4 d-flex align-items-center justify-content-between border-bottom-2"
    >
      <h4 class="mb-0">
        {{ task.docType || task.description }}<br />
        <span class="text-gray-400 fs-8 fw-bold">
          {{ task.description || task.docType }}
        </span>
      </h4>
      <div class="d-flex flex-column align-items-end justify-content-end mt-2">
        <div>
          <a
            *ngIf="task.allowUpload"
            href="javascript:void(0)"
            class="btn btn-sm bp-primary-bg-color float-end"
            (click)="openAddFiles(task)"
          >
            <i class="fas fa-paperclip text-white"></i> Add Files
          </a>
          <a
            *ngIf="task.borrowerTaskType == 'GatherPayment'"
            href="javascript:void(0)"
            class="btn btn-sm bp-primary-bg-color mx-2"
            (click)="openPaymentInfo(task)"
            >Add Payment Info
          </a>
          <a
            *ngIf="shouldShowEsignDocumentBtn(task)"
            href="javascript:void(0)"
            [ngClass]="{
              disabled:
                enabledTaskIdForESign != task.taskId &&
                task.borrowerTaskType === 'LosEsign'
            }"
            class="btn btn-sm bp-primary-bg-color mx-2"
            (click)="openEsignDocument(task)"
            >{{
              enabledTaskIdForESign != task.taskId &&
              task.borrowerTaskType === "LosEsign"
                ? "eSign Above Doc First"
                : "eSign Document"
            }}
          </a>
        </div>
        <div
          class="form-check mt-3 mb-2"
          *ngIf="
            task.borrowerTaskType === 'RequestDocument' &&
            isBorrowerPortal &&
            !task.isAgentTask &&
            task.allowMarkNA && !isCompanyPhm
          "
        >
          <input
            type="checkbox"
            class="form-check-input"
            id="taskNotApplicable{{ task.taskId }}"
            name="taskNotApplicable{{ task.taskId }}"
            [(ngModel)]="taskNotApplicable"
            (ngModelChange)="onTaskNotApplicableChanged(task)"
          />
          <label
            class="form-check-label"
            for="taskNotApplicable{{ task.taskI }}"
          >
            Not Applicable
          </label>
        </div>
      </div>
    </div>
    <div class="card-body p-4">
      <div
        class="d-flex align-items-center justify-content-between align-xs-items-start flex-xs-column"
      >
        <div class="d-flex">
          <div class="d-flex align-items-center" *ngIf="task.borrowerId">
            <span
              class="bp-contact-name-circle p-2 rounded-circle fw-bold d-flex align-items-center justify-content-center"
            >
              {{ task.borrowerName?.charAt(0) }}
            </span>
          </div>
          <div class="d-flex flex-column ms-2">
            <strong>{{ task?.borrowerName || "Unknown" }}</strong>
            <span class="text-nowrap">Due {{ task?.dueDate | dateAgo }}</span>
          </div>
        </div>
        <div class="mt-xs-5">
          <span
            class="badge rounded-pill bg-warning text-dark task-status-badge"
            *ngIf="
              task.borrowerFacingNote && task.borrowerTaskStatus == 'Rejected'
            "
            >Urgent Message</span
          >
          <span
            class="badge rounded-pill bg-light text-dark task-status-badge"
            *ngIf="
              task.borrowerFacingNote && task.borrowerTaskStatus != 'Rejected'
            "
            >New Message</span
          >
          <span
            class="badge rounded-pill bg-light-primary text-dark task-status-badge"
            *ngIf="task.linkedDocuments && task.linkedDocuments.length"
            >{{ task.linkedDocuments.length }} Documents</span
          >
          <span
            class="badge rounded-pill bg-light-warning text-dark task-status-badge"
            *ngIf="task.borrowerTaskStatus == 'Pending'"
            >Pending</span
          >
          <span
            class="badge rounded-pill bg-primary task-status-badge"
            *ngIf="task.borrowerTaskStatus == 'Submitted'"
            >Submitted</span
          >
          <span
            class="badge rounded-pill bg-alert text-dark task-status-badge"
            *ngIf="task.borrowerTaskStatus == 'Rejected'"
            >Rejected</span
          >
          <span
            class="badge rounded-pill bg-success text-dark task-status-badge"
            *ngIf="task.borrowerTaskStatus == 'Completed'"
            >Completed</span
          >

          <a
            *ngIf="task.templateDocumentUrl"
            title="View Template Doc"
            class="mx-2 bp-link"
            (click)="goToLink(task.templateDocumentUrl)"
          >
            <i class="far fa-eye"></i> Template Doc
          </a>
        </div>
      </div>

      <div class="row my-2">
        <div
          class="borrower-facing-note my-2 p-3 mx-4"
          style="max-width: max-content !important"
          *ngIf="task.borrowerFacingNote"
        >
          <small class="text-muted">Message from your loan team:</small>
          <br />{{ task?.borrowerFacingNote }}
        </div>
      </div>

      <div class="row my-2">
        <div class="d-flex justify-content-between mb-3">
          <label class="d-flex align-items-center"
            >Documents attached to this task:
            <strong class="ms-3" *ngIf="task.linkedDocuments.length == 0">
              No files found.</strong
            >
          </label>
        </div>
        <view-document
          [document]="document"
          *ngFor="let document of task['attachedDocuments']"
        ></view-document>
      </div>

      <p
        class="text-danger pt-0 px-3 pb-3"
        *ngIf="shouldShowEsignDocumentEmailTextMessage(task)"
      >
        Please look for an email from DocuSign in your inbox and open the link
        to sign this document.
      </p>
    </div>
  </div>
</div>
