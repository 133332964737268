import { Component, Injector, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PasswordResetSendCodeDialogComponent } from "../../../auth/password-reset-send-code-dialog/password-reset-send-code-dialog.component";
import { ExistingUserActionSelectionDialogComponent } from "../../../auth/existing-user-action-selection-dialog/existing-user-action-selection-dialog.component";
import { ResetPasswordDialogComponent } from "../../../auth/reset-password-dialog/reset-password-dialog.component";
import { AuthenticationRequest } from "../../../models/auth/auth-request.model";
import { LoginStatus } from "../../../models/auth/login-status.enum";
import { ResetPasswordResult } from "../../../models/auth/reset-password-result.model";
import { CreateBorrowerAccountMortgageRequest } from "../../../models/create-borrower-account-mortgage-request.model";
import { ErrorMessage } from "../../../models/error-message.model";
import { ExistingAccountLoginResult } from "../../../models/existing-account-login-result.model";
import { CreateAccountStep } from "../../../models/wizard/create-account-step.model";
import { AuthService } from "../../../services/auth.service";
import { NavigationService } from "../../../services/navigation.service";
import { WizardFlowConfigServiceBase } from "../../../services/wizard/wizard-flow-config-service.base";
import { WizardFlowServiceBase } from "../../../services/wizard/wizard-flow-service.base";
import { BorrowerInfoComponent } from "../../borrower-info/borrower-info.component";
import { WizardStepComponentBase } from "../wizard-step-base.component";
import { HttpClient } from "@angular/common/http";
import { HtmlViewerDialogComponent } from "projects/shared/html-viewer-dialog/html-viewer-dialog.component";
import { Constants } from "../../../services/constants";
import { firstValueFrom, Subscription } from "rxjs";
import { MortgageApplicationService } from "../../../services/mortgage-application.service";
import { CompanyInfo } from "../../../models/company-info.model";
import { EnvironmentService } from "projects/shared/services/environment.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'create-account-step',
  templateUrl: 'create-account-step.component.html',
  styleUrls: ['create-account-step.component.scss']
})
export class CreateAccountStepComponent extends WizardStepComponentBase<CreateAccountStep> implements OnInit {

  @ViewChild(BorrowerInfoComponent) borrowerInfo: BorrowerInfoComponent | undefined;

  error: any | undefined = undefined;
  userExists: boolean = false;

  passwordPolicyViolations: string[] | null = null;

  isSubmittedInEditMode: boolean = false;

  protected companyInfo: CompanyInfo = null;

  protected companyName: string = "Lodasoft";

  protected optInForReceivingSms: boolean = false;

  protected companySpecificAdditionalDisclaimer: string = "";

  private _activatedRouteQueryParamsSubscription: Subscription;
  private _activatedRouteParamsSubscription: Subscription;

  private _queryParams: string = "";

  get userCreationAutoConfirmed(): boolean {
    return this._wizardFlowConfigService.flowConfigs.userCreationAutoConfirmed;
  }

  set userCreationAutoConfirmed(value: boolean) {
    this._wizardFlowConfigService.flowConfigs.userCreationAutoConfirmed = value;
  }

  constructor(private readonly _injector: Injector,
    private readonly _wizardFlowService: WizardFlowServiceBase,
    private readonly _wizardFlowConfigService: WizardFlowConfigServiceBase,
    private readonly _navigationService: NavigationService,
    private readonly _modalService: NgbModal,
    private readonly _authService: AuthService,
    private readonly _mortgageApplicationService: MortgageApplicationService,
    private readonly _environment: EnvironmentService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _http: HttpClient) {
    super(_injector);
    if (this.wizardFlowService.context.hasSuccessfullyPulledExistingApplication
      && this.mortgageApplication && this.mortgageApplication.borrowers.length > 0) {
      this.step.borrower = this.mortgageApplication.borrowers[0];
    }
  }

  ngOnInit(): void {
    this._activatedRouteQueryParamsSubscription = this._activatedRoute.queryParams.subscribe((queryParams) => {
			this._activatedRouteParamsSubscription = this._activatedRoute.params.subscribe((routeParams) => {
				Object.keys(queryParams).forEach(key => {
					this._queryParams += `${key}=${queryParams[key]}&`;
				});
        this.getCompanyInfo();
			});
		});
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._activatedRouteQueryParamsSubscription?.unsubscribe();
    this._activatedRouteParamsSubscription?.unsubscribe();
  }

  onLoginClicked = () => {
    this._navigationService.navigateToLogin();
  }

  onNextClicked() {
    this.error = undefined;
    if (this.wizardFlowService.isEditEnabled) {
      this.isSubmittedInEditMode = true;
      return;
    }
    if (this.borrowerInfo?.validate()) {
      localStorage.setItem("borrowerIndex", "0");
      if (this._wizardFlowService.companyGuid) {
        this.startSpinner();

        this.mortgageApplicationService.checkIfBorrowerExists(this._wizardFlowService.companyGuid, this.borrowerInfo.borrower?.primaryEmail).subscribe(response => {
          this.stopSpinner();
          if (response.userExists) {
            // User exists, we need to ask them what user is intending to do - either create an app, or login and manage applications
            this.showRequestPasswordDialog(this.step.borrower!.primaryEmail);
          } else {
            if (!this.userCreationAutoConfirmed) {
              // If user does not exist, the backend sent the confirmation email, nothing to do here any further
              this.createUserAccountAndMortgageWhenNotAutoConfirmed()
            } else {
              // User does not exists, and it's auto-confirmed, so we need to send the userid/password and create the account and the app
              this.createUserAccountAndMortgageWhenAutoConfirmed();
            }
          }
        }, error => {
          this.stopSpinner();
        })
      }
    }
  }

  protected onShowPrivacyPolicyClicked = async () => {
    const privacyStatement = await this.getPrivacyStatement();
    const modalRef = this._modalService.open(HtmlViewerDialogComponent, Constants.modalOptions.xlarge);
    modalRef.componentInstance.title = "Privacy Policy";
    modalRef.componentInstance.htmlContent = privacyStatement;

    modalRef.result.then(() => {
    }, error => { });
  }

  protected onShowTermsOfServiceClicked = async () => {
    const termsOfService = await this.getTermsOfUse();
    const modalRef = this._modalService.open(HtmlViewerDialogComponent, Constants.modalOptions.xlarge);
    modalRef.componentInstance.title = "Terms of Service";
    modalRef.componentInstance.htmlContent = termsOfService;

    modalRef.result.then(() => {
    }, error => { });
  }

  private getCompanyInfo = () => {
    this._mortgageApplicationService.getCompanyInfo(this._wizardFlowService.companyGuid).subscribe(response => {
      this.companyInfo = response;
      this.companyName = this.companyInfo.companyName;
      if (this.companyInfo.companyId === 263) {
        this.companySpecificAdditionalDisclaimer = "Reply HELP for more information.";
      }

      if (this.companyInfo.externalAuthEnabled) {
        this.redirectToExtternalAuthProvider();
      }
    }, error => {
    });
  }

  private redirectToExtternalAuthProvider = () => {
    const mortgageToSave: any = {
      subjectProperty: this.mortgageApplication.subjectProperty,
      extension: this.mortgageApplication.extension,
      userGuid: this.mortgageApplication.userGuid,
    }
    const createApplicationRequest: CreateBorrowerAccountMortgageRequest = {
      companyGuid: this._wizardFlowService.companyGuid,
      campaignId: this._wizardFlowService.campaignId,
      leadId: this._wizardFlowService.leadId,
      leadGuid: this._wizardFlowService.leadGuid,
      leadSourceOverride: this._wizardFlowService.leadSourceOverride || this._wizardFlowService.context.leadSource,
      mortgage: mortgageToSave,
      userGuid: this.mortgageApplication.userGuid,
      referralSource: this._wizardFlowService.referralSource || undefined,
      flowGuid: this._wizardFlowConfigService.flowConfigs.guid,
      loanPurposeId: this._wizardFlowService.context.lead?.loanPurposeId || this._wizardFlowService.context.loanPurposeId,
    }

    const base64String = btoa(JSON.stringify(createApplicationRequest));
    const returnUrl = `${window.location.protocol}//${window.location.host}/create-account-ext-auth?s=${base64String}${this._queryParams ? '&' + this._queryParams : ''}`;
    const challengeUrl = this._environment.apiInfo.apiBaseUrl + `/api/auth/connect/challenge/${this._wizardFlowService.companyGuid}/Borrower?returnUrl=${encodeURIComponent(returnUrl)}`;
    window.location.href = challengeUrl;
  }

  private createUserAccountAndMortgageWhenAutoConfirmed = () => {
    const createApplicationRequest: CreateBorrowerAccountMortgageRequest = {
      companyGuid: this._wizardFlowService.companyGuid,
      password: this.borrowerInfo.password,
      campaignId: this._wizardFlowService.campaignId,
      leadId: this._wizardFlowService.leadId,
      leadGuid: this._wizardFlowService.leadGuid,
      leadSourceOverride: this._wizardFlowService.leadSourceOverride || this._wizardFlowService.context.leadSource,
      mortgage: this.mortgageApplication,
      userGuid: this.mortgageApplication.userGuid,
      referralSource: this._wizardFlowService.referralSource || undefined,
      flowGuid: this._wizardFlowConfigService.flowConfigs.guid,
      loanPurposeId: this._wizardFlowService.context.lead?.loanPurposeId || this._wizardFlowService.context.loanPurposeId,
      smsOptIn: this.optInForReceivingSms
    }
    this.startSpinner();
    this.mortgageApplicationService.saveLoanApplication(createApplicationRequest).subscribe(
      response => {
        this.loginAndGotoApplyFlow(this.step.borrower.primaryEmail, <string>createApplicationRequest.password);
      },
      error => {
        this.showError({
          title: "An error occurred creating your account!",
          message: error.error && error.error.message ? error.error.message : "An error occurred creating your account!"
        })
      }
    ).add(() => {
      this.stopSpinner();
    });
  }

  private createUserAccountAndMortgageWhenNotAutoConfirmed = () => {
    const createApplicationRequest: CreateBorrowerAccountMortgageRequest = {
      companyGuid: this._wizardFlowService.companyGuid,
      password: null,
      campaignId: this._wizardFlowService.campaignId,
      leadId: this._wizardFlowService.leadId,
      leadGuid: this._wizardFlowService.leadGuid,
      leadSourceOverride: this._wizardFlowService.leadSourceOverride || this._wizardFlowService.context.leadSource,
      mortgage: this.mortgageApplication,
      userGuid: this.mortgageApplication.userGuid,
      referralSource: this._wizardFlowService.referralSource || undefined,
      flowGuid: this._wizardFlowConfigService.flowConfigs.guid,
      loanPurposeId: this._wizardFlowService.context.lead?.loanPurposeId || this._wizardFlowService.context.loanPurposeId,
      smsOptIn: this.optInForReceivingSms
    }
    this.startSpinner();
    this.mortgageApplicationService.saveLoanApplication(createApplicationRequest).subscribe(
      response => {
        this._navigationService.navigateToCheckEmailNotice(this.step.borrower!.primaryEmail);
      },
      error => {
        if (this.isPasswordPolicyViolation(error)) {
          let passwordPolicyErrors = error.error.message.match(/\[.*?\]/g);
          this.passwordPolicyViolations = passwordPolicyErrors.map((e: string) => e.replace(/[\[\]']+/g, ''));
          return;
        }
        this.showError({
          title: "An error occurred creating your account!",
          message: error.error && error.error.message ? error.error.message : "An error occurred creating your account!"
        })
      }
    ).add(() => {
      this.stopSpinner();
    });
  }

  private isPasswordPolicyViolation = (error: any): boolean => {
    return error.error && error.error.message && error.error.message.includes('[') && error.error.message.includes(']')
  }

  private showRequestPasswordDialog = (userName: string) => {
    const createApplicationRequest: CreateBorrowerAccountMortgageRequest = {
      companyGuid: this._wizardFlowService.companyGuid,
      password: this.borrowerInfo.password,
      campaignId: this._wizardFlowService.campaignId,
      leadId: this._wizardFlowService.leadId,
      leadGuid: this._wizardFlowService.leadGuid,
      leadSourceOverride: this._wizardFlowService.leadSourceOverride || this._wizardFlowService.context.leadSource,
      mortgage: this.mortgageApplication,
      userGuid: this.mortgageApplication.userGuid,
      referralSource: this._wizardFlowService.referralSource || undefined,
      flowGuid: this._wizardFlowConfigService.flowConfigs.guid,
      loanPurposeId: this._wizardFlowService.context.lead?.loanPurposeId || this._wizardFlowService.context.loanPurposeId,
      smsOptIn: this.optInForReceivingSms
    }
    const modal = this._modalService.open(ExistingUserActionSelectionDialogComponent, { centered: true, windowClass: 'requestPasswordModalClass' });
    modal.componentInstance.userName = userName;
    modal.componentInstance.createApplicationRequest = createApplicationRequest;
    modal.result.then((result: ExistingAccountLoginResult) => {
      if (result.userForgotPassword) {
        console.log("User forgot password!");
        this.showSendCodeForResettingPasswordDialog(createApplicationRequest, result.applyingForANewLoan);
      } else if (result.authToken) {
        this.loginAndGotoApplyFlow(userName, <string>createApplicationRequest.password, result.appId);
      }
    }, err => { });
  }

  private showSendCodeForResettingPasswordDialog = (createApplicationRequest: CreateBorrowerAccountMortgageRequest,
    applyingForANewLoan: boolean) => {
    const modal = this._modalService.open(PasswordResetSendCodeDialogComponent, { centered: true, windowClass: 'requestPasswordModalClass' });
    modal.componentInstance.email = this.borrowerInfo.borrower.primaryEmail;
    modal.result.then((email: string) => {
      this.showResetPasswordDialog(email, createApplicationRequest, applyingForANewLoan);
    }, error => { });
  }

  private showResetPasswordDialog = (userName: string, createApplicationRequest: CreateBorrowerAccountMortgageRequest,
    applyingForANewLoan: boolean) => {
    const modal = this._modalService.open(ResetPasswordDialogComponent, { centered: true, windowClass: 'requestPasswordModalClass' });
    modal.componentInstance.applyingForANewLoan = applyingForANewLoan;
    modal.componentInstance.userName = userName;
    modal.componentInstance.createApplicationRequest = createApplicationRequest;
    modal.result.then((result: ResetPasswordResult) => {
      if (result.authToken) {
        this.loginAndGotoApplyFlow(userName, <string>result.newPassword);
      }
    }, error => { });
  }

  private loginAndGotoApplyFlow = (userName: string, password: string, appId?: number) => {
    const loginRequest: AuthenticationRequest = {
      companyGuid: this._wizardFlowService.companyGuid,
      username: userName,
      password: password,
      rememberMe: false,
      scope: 'Borrower',
      usernameValidationOnly: false
    }
    this.spinner.show();
    this._authService.signIn(loginRequest).subscribe(response => {
      this.spinner.hide();
      if (response.loginStatus === LoginStatus.Error) {
        this.showError(new ErrorMessage("An error occurred logging you in.", response.errorMessage));
        return;
      }
      this._navigationService.navigateToPath("apply", true, undefined, (appId ? { appId: appId } : null));
    }, error => {
      this.spinner.hide();
      this.showError(new ErrorMessage("An error occurred logging you in.", error?.message));
    });
  }

  private getPrivacyStatement = async (): Promise<string> => {
    const url = `./assets/privacy-statement.html`;
    return firstValueFrom(this._http.get(url, { responseType: 'text' }));
  }

  private getTermsOfUse = async (): Promise<string> => {
    const url = `./assets/terms-of-use.html`;
    return firstValueFrom(this._http.get(url, { responseType: 'text' }));
  }
}
