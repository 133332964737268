<div class="container p-xs-0" *ngIf="application">
  <div class="row my-3">
    <div class="col-md-12 d-flex align-items-stretch d-revert">
      <loan-info
        [isAgent]="isAgent"
        [showViewTasksButton]="false"
        [showBorrowerContactDetails]="true"
        [application]="application"
      ></loan-info>
    </div>
  </div>
  <div class="row my-3">
    <div class="col-md-9">
      <portal-task-information
        #tasksView
        [appId]="loanId"
        [isAgent]="isAgent"
        (tasksLoaded)="onTasksLoaded($event)">
      </portal-task-information>
    </div>
    <div class="col-md-3">
      <div class="row mb-2" *ngIf="actionsApplicableToLoan && shouldShowLoanActions">
        <loan-actions
          [application]="application"
          [actionsApplicable]="actionsApplicableToLoan"
          (openAssetVerificationClicked)="onOpenAssetVerificationClicked()"
          (openIncomeVerificationClicked)="onOpenIncomeVerificationClicked()"
          (additionalDocumentsAddedToLoan)="onAdditionalDocumentsAddedToLoan()">
        </loan-actions>
      </div>
      <div class="row mb-2">
        <loan-messages [loanId]="loanId"></loan-messages>
      </div>
      <div class="row" *ngIf="borrowerContact">
        <loan-contacts [borrowerContact]="borrowerContact"></loan-contacts>
      </div>
    </div>
  </div>
</div>
