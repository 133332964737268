<form #reoPropertiesForm="ngForm" novalidate id="reoPropertiesForm" name="reoPropertiesForm">
  <div class="row" [ngClass]="{'mb-8': !isMobile}">
    <div class="col-md-6 fv-row" *ngIf="!isHidden('reo.typeOfProperty')">
      <label class="form-label mb-2" [ngClass]="{'required': isRequired('reo.typeOfProperty')}">{{getLabel('reo.typeOfProperty', 'Property Type')}}</label>
      <select name="propertyType{{uniqueId}}" id="propertyType{{uniqueId}}" [ngClass]="{'is-invalid' : propertyType && propertyType.touched && propertyType.invalid}"
        [required]="isRequired('reo.typeOfProperty')"
        #propertyType="ngModel" class="form-select form-select-solid" [(ngModel)]="reo!.typeOfProperty"
        data-control="select2" data-hide-search="true" data-placeholder="Choose one">
        <option></option>
        <option #reoItem *ngFor="let propertyType of propertyTypes; let i = index" value="{{propertyType.value}}">{{propertyType.name}}</option>
      </select>
      <div class="invalid-feedback">Property Type is required.</div>
    </div>
    <div class="col-md-6 fv-row" *ngIf="!isHidden('reo.marketValue')">
      <label class="form-label mb-2" [ngClass]="{'required': isRequired('reo.marketValue')}">{{getLabel('reo.marketValue', 'Property Value')}}</label>
      <input class="form-control form-control-solid" mask="separator" thousandSeparator="," inputmode="decimal" prefix="$"
        [required]="isRequired('reo.marketValue')"
        (change)="onPropertyValueChanged()"
        [ngClass]="{'is-invalid' : propertyValue && propertyValue.touched && propertyValue.invalid}"
        #propertyValue="ngModel" [(ngModel)]="reo!.marketValue" placeholder="" name="propertyValue{{uniqueId}}" id="propertyValue{{uniqueId}}"/>
      <div class="invalid-feedback">Property Value is required.</div>
    </div>
  </div>
  <div class="row" [ngClass]="{'mb-8': !isMobile}">
    <div class="col-md-6 fv-row" *ngIf="!isHidden('reo.purchasePrice')">
      <label class="form-label mb-2" [ngClass]="{'required': isRequired('reo.purchasePrice')}">{{getLabel('reo.purchasePrice', 'Original Purchase Price')}}</label>
      <input class="form-control form-control-solid" mask="separator" thousandSeparator="," inputmode="decimal" prefix="$"
        [required]="isRequired('reo.purchasePrice')"
        (change)="onPurchasePriceChanged()"
        [ngClass]="{'is-invalid' : purchasePrice && purchasePrice.touched && purchasePrice.invalid}"
        #purchasePrice="ngModel" [(ngModel)]="reo!.purchasePrice" placeholder="" name="purchasePrice{{uniqueId}}" id="purchasePrice{{uniqueId}}"/>
    <div class="invalid-feedback">Purchase Price is required.</div>
    </div>
    <div class="col-md-6 fv-row" *ngIf="!isHidden('reo.dateAcquired')">
      <label class="form-label mb-2" [ngClass]="{'required': isRequired('reo.dateAcquired')}">{{getLabel('reo.dateAcquired', 'Date Acquired')}}</label>
      <div class="position-relative d-flex align-items-center">
        <input class="form-control form-control-solid" matInput mask="M0/d0/0000" [showMaskTyped]="true" autocomplete="off"
          [specialCharacters]="[ '/' ]" inputmode="numeric" [dropSpecialCharacters]="false" datePicker #dateAcquired="ngModel"
          [(ngModel)]="reo.dateAcquired" placeholder="" name="dateAcquired{{uniqueId}}" id="dateAcquired{{uniqueId}}" ngControl="dateAcquired" id="dateAcquired"
          [ngClass]="{'is-invalid' : dateAcquired && dateAcquired.touched && dateAcquired.invalid}"
          [required]="isRequired('reo.dateAcquired')"/>
        <div class="invalid-feedback">Date Acquired is required.</div>
      </div>
    </div>
  </div>
  <div class="row" [ngClass]="{'mb-8': !isMobile}">
    <div class="col-md-4 fv-row" *ngIf="!isHidden('reo.currentUsageType')">
      <label class="form-label mb-2" [ngClass]="{'required': isRequired('reo.currentUsageType')}">{{getLabel('reo.currentUsageType', 'How do you currently use this property?')}}</label>
      <select name="currentUsageType{{uniqueId}}" id="currentUsageType{{uniqueId}}" [ngClass]="{'is-invalid' : currentUsageType && currentUsageType.touched && currentUsageType.invalid}"
        [required]="isRequired('reo.currentUsageType')"
        #currentUsageType="ngModel" class="form-select form-select-solid" [(ngModel)]="reo!.currentUsageType"
        data-control="select2" data-hide-search="true" data-placeholder="Choose one">
      <option></option>
      <option #reoItem *ngFor="let usageType of usageTypes; let i = index" value="{{usageType.value}}">{{usageType.name}}</option>
    </select>
    <div class="invalid-feedback">This field is required.</div>
    </div>

    <div class="col-md-4 fv-row">
      <label class="form-label mb-2 required">Do you plan on selling this property?</label>
      <select name="dispositionStatus{{uniqueId}}" id="dispositionStatus{{uniqueId}}"
        [ngClass]="{'is-invalid' : willPropertyBeSoldValue && willPropertyBeSoldValue.touched && willPropertyBeSoldValue.invalid}"
        [required]="true"
        #willPropertyBeSoldValue="ngModel" class="form-select form-select-solid"
        [(ngModel)]="willPropertyBeSold"
        (ngModelChange)="onPropertyWillBeSoldChanged()"
        data-control="select2" data-hide-search="true" data-placeholder="Choose one">
      <option value="no">No</option>
      <option value="yes">Yes</option>
    </select>
    <div class="invalid-feedback">This field is required.</div>
    </div>

    <div class="col-md-4 fv-row" *ngIf="!isHidden('reo.intendedUsageType') && willPropertyBeSold === 'no'">
      <label class="form-label mb-2" [ngClass]="{'required': isRequired('reo.intendedUsageType')}">{{getLabel('reo.intendedUsageType', 'How do you plan on using this property?')}}</label>
      <select name="intendedUsageType{{uniqueId}}" id="intendedUsageType{{uniqueId}}" [ngClass]="{'is-invalid' : intendedUsageType && intendedUsageType.touched && intendedUsageType.invalid}"
        [required]="isRequired('reo.intendedUsageType')"
        #intendedUsageType="ngModel" class="form-select form-select-solid" [(ngModel)]="reo!.intendedUsageType"
        data-control="select2" data-hide-search="true" data-placeholder="Choose one">
      <option></option>
      <option #reoItem *ngFor="let usageType of usageTypes; let i = index" value="{{usageType.value}}">{{usageType.name}}</option>
    </select>
    <div class="invalid-feedback">This field is required.</div>
    </div>

  </div>
  <div class="row" [ngClass]="{'mb-8': !isMobile}">
    <div class="col-md-3 fv-row" *ngIf="!isHidden('reo.amountOfMortgage')">
      <label class="form-label mb-2" [ngClass]="{'required': isRequired('reo.amountOfMortgage')}">{{getLabel('reo.amountOfMortgage', 'Current balance of existing liens on this property?')}}</label>
      <input class="form-control form-control-solid" mask="separator" thousandSeparator="," inputmode="decimal" prefix="$"
        [ngClass]="{'is-invalid' : amountOfMortgage && amountOfMortgage.touched && amountOfMortgage.invalid}"
        [required]="isRequired('reo.amountOfMortgage')"
        #amountOfMortgage="ngModel" [(ngModel)]="reo!.amountOfMortgage" placeholder="" name="amountOfMortgage{{uniqueId}}" id="amountOfMortgage{{uniqueId}}" />
      <div class="invalid-feedback">This field is required.</div>
    </div>
    <div class="col-md-3 fv-row" *ngIf="!isHidden('reo.mortgagePayment')">
      <label class="form-label mb-2" [ngClass]="{'required': isRequired('reo.mortgagePayment')}">{{getLabel('reo.mortgagePayment', 'Monthly Payment')}}</label>
      <input class="form-control form-control-solid" mask="separator" thousandSeparator="," inputmode="decimal" prefix="$"
        [ngClass]="{'is-invalid' : mortgagePayment && mortgagePayment.touched && mortgagePayment.invalid}"
        [required]="isRequired('reo.mortgagePayment')"
        #mortgagePayment="ngModel" [(ngModel)]="reo!.mortgagePayment" placeholder="" name="mortgagePayment{{uniqueId}}" id="mortgagePayment{{uniqueId}}" />
      <div class="invalid-feedback">This field is required.</div>
    </div>
    <div class="col-md-3 fv-row" *ngIf="!isHidden('reo.monthlyMiscExpenses')">
      <label class="form-label mb-2" [ngClass]="{'required': isRequired('reo.monthlyMiscExpenses')}">{{getLabel('reo.monthlyMiscExpenses', 'Insurance, HOA, Tax')}}</label>
      <input class="form-control form-control-solid" mask="separator" thousandSeparator="," inputmode="decimal" prefix="$"
        [ngClass]="{'is-invalid' : monthlyMiscExpenses && monthlyMiscExpenses.touched && monthlyMiscExpenses.invalid}"
        [required]="isRequired('reo.monthlyMiscExpenses')"
        #monthlyMiscExpenses="ngModel" [(ngModel)]="reo!.monthlyMiscExpenses" placeholder="" name="monthlyMiscExpenses{{uniqueId}}" id="monthlyMiscExpenses{{uniqueId}}" />
      <div class="invalid-feedback">This field is required.</div>
    </div>
    <div class="col-md-3 fv-row" *ngIf="!isHidden('reo.grossRentalIncome')">
      <label class="form-label mb-2" [ngClass]="{'required': isRequired('reo.grossRentalIncome')}">{{getLabel('reo.grossRentalIncome', 'Gross Rental Income')}}</label>
      <input class="form-control form-control-solid" mask="separator" thousandSeparator="," inputmode="decimal" prefix="$"
        [ngClass]="{'is-invalid' : grossRentalIncome && grossRentalIncome.touched && grossRentalIncome.invalid}"
        [required]="isRequired('reo.monthlyMiscExpenses')"
        #grossRentalIncome="ngModel" [(ngModel)]="reo!.grossRentalIncome" placeholder="" name="grossRentalIncome{{uniqueId}}" id="grossRentalIncome{{uniqueId}}" />
      <div class="invalid-feedback">This field is required.</div>
    </div>
  </div>
</form>
